import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { collection, query, orderBy, limit, onSnapshot } from 'firebase/firestore';
import { db } from './services/firebase';
import TransactionList from './components/TransactionList';
import TokenRankings from './components/TokenRankings';
import TokenDetails from './components/TokenDetails';
import ChartAnalysis from './components/ChartAnalysis';
import FilterBar from './components/FilterBar';
import './App.css';

function App() {
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timeFilter, setTimeFilter] = useState('24h');

  useEffect(() => {
    try {
      const q = query(
        collection(db, 'transactions'),
        orderBy('time_frame', 'desc'),
        limit(100)
      );

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const transactionData = [];
        querySnapshot.forEach((doc) => {
          transactionData.push({ ...doc.data(), id: doc.id });
        });
        setTransactions(transactionData);
        setLoading(false);
      });

      return () => unsubscribe();
    } catch (err) {
      console.error('Error fetching transactions:', err);
      setError(err.message);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const filterTransactionsByTime = (tx) => {
      const now = new Date();
      const txTime = new Date(tx.time_frame);
      const diffInMinutes = (now - txTime) / (1000 * 60);

      switch (timeFilter) {
        case '2m':
          return diffInMinutes <= 2;
        case '5m':
          return diffInMinutes <= 5;
        case '15m':
          return diffInMinutes <= 15;
        case '30m':
          return diffInMinutes <= 30;
        case '1h':
          return diffInMinutes <= 60;
        case '2h':
          return diffInMinutes <= 120;
        case '3h':
          return diffInMinutes <= 180;
        case '24h':
          return diffInMinutes <= 1440;
        case '7d':
          return diffInMinutes <= 10080;
        default:
          return true;
      }
    };

    const filtered = transactions.filter(filterTransactionsByTime);
    setFilteredTransactions(filtered);
  }, [timeFilter, transactions]);

  if (loading) {
    return <div className="loading">Loading transactions...</div>;
  }

  if (error) {
    return <div className="error">Error: {error}</div>;
  }

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/token/:tokenAddress" element={<TokenDetails />} />
          <Route path="/" element={
            <>
              <FilterBar onTimeFilterChange={setTimeFilter} currentFilter={timeFilter} />
              <div className="grid-container">
                <TokenRankings timeFilter={timeFilter} />
              </div>
              <ChartAnalysis transactions={filteredTransactions} />
              <TransactionList transactions={transactions} />
            </>
          } />
        </Routes>
      </div>
    </Router>
  );
}

export default App; 