import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './TokenRankings.css';
import config from '../config';

const TokenRankings = ({ timeFilter }) => {
  const navigate = useNavigate();
  const [rankings, setRankings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getTimeWindowValue = (filter) => {
    const timeMap = {
      '15m': '15',
      '30m': '30',
      '1h': '60',
      '2h': '120',
      '3h': '180',
      '24h': '1440',
      '7d': '10080',
      'all': '10080'
    };
    return timeMap[filter] || '1440';
  };

  useEffect(() => {
    const fetchRankings = async () => {
      try {
        setLoading(true);
        const timeWindow = getTimeWindowValue(timeFilter);
        const apiUrl = `${config.API_BASE_URL}/api/token-rankings?timeWindow=${timeWindow}`;
        console.log('Original API URL:', apiUrl);

        const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
        const fullUrl = proxyUrl + apiUrl;
        console.log('Fetching from:', fullUrl);

        const response = await fetch(fullUrl, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          }
        });

        console.log('Response status:', response.status);
        
        if (!response.ok) {
          const errorText = await response.text();
          console.error('Error response:', errorText);
          throw new Error(`Server error: ${errorText}`);
        }

        const data = await response.json();
        console.log('Received data:', data);
        
        if (data.status === 'success') {
          setRankings(data.data);
        } else {
          throw new Error(data.message || 'Failed to fetch rankings');
        }
      } catch (error) {
        console.error('Detailed error:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRankings();
  }, [timeFilter]);

  console.log('Current rankings state:', rankings);

  const handleTokenClick = (tokenName, tokenAddress, action) => {
    const timeWindowMap = {
      '15m': '15',
      '30m': '30',
      '1h': '60',
      '2h': '120',
      '3h': '180',
      '24h': '1440',
      '7d': '10080',
      'all': '10080'
    };
    
    const timeWindow = timeWindowMap[timeFilter] || '1440';
    navigate(`/token/${tokenAddress}?action=${action}&timeWindow=${timeWindow}`);
  };

  if (loading) return <div className="rankings-loading">Loading...</div>;
  if (error) return <div className="rankings-error">Error: {error}</div>;

  return (
    <div className="rankings-container">
      <div className="rankings-grid">
        <div className="rankings-column">
          <h2>🔥 Top Bought Tokens</h2>
          {Object.entries(rankings.bought).map(([tokenName, data]) => {
            console.log('Processing bought token:', tokenName, data);
            
            return (
              <div 
                key={data.address} 
                className="token-card"
                onClick={() => handleTokenClick(tokenName, data.address, 'bought')}
                style={{ cursor: 'pointer' }}
              >
                <div className="rank">#{data.rank}</div>
                <div className="token-info">
                  <h3>{tokenName}</h3>
                  <p>Count: {data.count || 0}</p>
                </div>
              </div>
            );
          })}
        </div>
        
        <div className="rankings-column">
          <h2>💰 Top Sold Tokens</h2>
          {Object.entries(rankings.sold).map(([tokenName, data]) => {
            console.log('Processing sold token:', tokenName, data);
            
            return (
              <div 
                key={data.address} 
                className="token-card"
                onClick={() => handleTokenClick(tokenName, data.address, 'sold')}
                style={{ cursor: 'pointer' }}
              >
                <div className="rank">#{data.rank}</div>
                <div className="token-info">
                  <h3>{tokenName}</h3>
                  <p>Count: {data.count || 0}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TokenRankings; 