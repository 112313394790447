import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import TransactionList from './TransactionList';
import './TokenDetails.css';
import config from '../config';

function TokenDetails() {
  const { tokenAddress } = useParams();
  const [searchParams] = useSearchParams();
  const action = searchParams.get('action') || 'all';
  const timeWindow = searchParams.get('timeWindow') || '1440';

  const [transactions, setTransactions] = useState([]);
  const [tokenInfo, setTokenInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTokenTransactions = async () => {
      try {
        setLoading(true);
        const apiUrl = `${config.API_BASE_URL}/api/token-transactions/${tokenAddress}?action=${action}&timeWindow=${timeWindow}`;
        console.log('Original API URL:', apiUrl);

        // Use cors-anywhere as proxy
        const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
        const fullUrl = proxyUrl + apiUrl;
        console.log('Fetching from:', fullUrl);

        const response = await fetch(fullUrl, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          }
        });

        console.log('Response status:', response.status);
        
        if (!response.ok) {
          const errorText = await response.text();
          console.error('Error response:', errorText);
          throw new Error(`Server error: ${errorText}`);
        }

        const data = await response.json();
        console.log('Received data:', data);
        
        if (data.status === 'success') {
          setTransactions(data.transactions);
          setTokenInfo(data.tokenInfo);
        } else {
          throw new Error(data.message || 'Failed to fetch token transactions');
        }
      } catch (error) {
        console.error('Error fetching token transactions:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTokenTransactions();
  }, [tokenAddress, action, timeWindow]);

  if (loading) {
    return <div className="token-details-loading">Loading token details...</div>;
  }

  if (error) {
    return <div className="token-details-error">Error: {error}</div>;
  }

  return (
    <div className="token-details-container">
      {tokenInfo && (
        <div className="token-info-header">
          <h1>{tokenInfo.name}</h1>
          <div className="token-stats">
            <div className="stat-item">
              <label>Price:</label>
              <span>${tokenInfo.price || 'N/A'}</span>
            </div>
            <div className="stat-item">
              <label>Market Cap:</label>
              <span>${tokenInfo.marketCap || 'N/A'}</span>
            </div>
            <div className="stat-item">
              <label>24h Volume:</label>
              <span>{transactions.length} transactions</span>
            </div>
          </div>
          <div className="token-filters">
            <select 
              value={action} 
              onChange={(e) => {
                const newSearchParams = new URLSearchParams(searchParams);
                newSearchParams.set('action', e.target.value);
                window.history.pushState({}, '', `?${newSearchParams.toString()}`);
              }}
            >
              <option value="all">All Actions</option>
              <option value="bought">Bought</option>
              <option value="sold">Sold</option>
            </select>
            <select 
              value={timeWindow}
              onChange={(e) => {
                const newSearchParams = new URLSearchParams(searchParams);
                newSearchParams.set('timeWindow', e.target.value);
                window.history.pushState({}, '', `?${newSearchParams.toString()}`);
              }}
            >
              <option value="2">Last 2 Minutes</option>
              <option value="5">Last 5 Minutes</option>
              <option value="15">Last 15 Minutes</option>
              <option value="30">Last 30 Minutes</option>
              <option value="60">Last Hour</option>
              <option value="120">Last 2 Hours</option>
              <option value="180">Last 3 Hours</option>
              <option value="1440">Last 24 Hours</option>
              <option value="10080">Last 7 Days</option>
            </select>
          </div>
        </div>
      )}
      
      <TransactionList transactions={transactions} />
    </div>
  );
}

export default TokenDetails;
