import React from 'react';
import './FilterBar.css';

function FilterBar({ onTimeFilterChange, currentFilter }) {
  const timeFilters = [
    { value: '2m', label: '2 Min' },
    { value: '5m', label: '5 Min' },
    { value: '15m', label: '15 Min' },
    { value: '30m', label: '30 Min' },
    { value: '1h', label: 'Last Hour' },
    { value: '2h', label: '2 Hours' },
    { value: '3h', label: '3 Hours' },
    { value: '24h', label: 'Last 24H' },
    { value: '7d', label: 'Last 7 Days' },
    { value: 'all', label: 'All Time' }
  ];

  return (
    <div className="filter-bar">
      <div className="time-filters">
        {timeFilters.map(filter => (
          <button
            key={filter.value}
            className={`filter-button ${currentFilter === filter.value ? 'active' : ''}`}
            onClick={() => onTimeFilterChange(filter.value)}
          >
            {filter.label}
          </button>
        ))}
      </div>
    </div>
  );
}

export default FilterBar; 