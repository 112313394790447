import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAOLypdz5pEhqnhgw03PwFwXPxO59l9ELY",
  authDomain: "sw-analysis.firebaseapp.com",
  projectId: "sw-analysis",
  storageBucket: "sw-analysis.firebasestorage.app",
  messagingSenderId: "535662330517",
  appId: "1:535662330517:web:af215b4b63464c7fde519c",
  measurementId: "G-DW32FFR11D"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app); 