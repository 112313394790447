import React, { useState } from 'react';
import './TransactionList.css';

const formatTokenInfo = (tokenInfo) => {
  if (!tokenInfo) return '';
  if (typeof tokenInfo === 'string') return tokenInfo;
  if (typeof tokenInfo === 'object') {
    if (tokenInfo.raw_info) return tokenInfo.raw_info;
    if (tokenInfo.price || tokenInfo.market_cap) {
      return `$${tokenInfo.price || '0'} M:$${tokenInfo.market_cap || '0'}`;
    }
  }
  return '';
};

function TransactionList({ transactions }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [jumpToPage, setJumpToPage] = useState('');

  const getActionEmoji = (action) => {
    switch (action) {
      case 'bought':
        return '🟢';
      case 'sold':
        return '🔴';
      case 'received':
        return '➡️';
      default:
        return '•';
    }
  };

  const formatTimeAgo = (timestamp) => {
    const now = new Date();
    const txTime = new Date(timestamp);
    const diffInMinutes = Math.floor((now - txTime) / (1000 * 60));
    
    if (diffInMinutes < 1) return 'just now';
    if (diffInMinutes === 1) return '1 minute ago';
    if (diffInMinutes < 60) return `${diffInMinutes} minutes ago`;
    return txTime.toLocaleString();
  };

  // Pagination calculations
  const totalPages = Math.ceil(transactions.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTransactions = transactions.slice(indexOfFirstItem, indexOfLastItem);

  // Page navigation handlers
  const handlePrevPage = () => {
    setCurrentPage(prev => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prev => Math.min(prev + 1, totalPages));
  };

  const handleJumpToPage = (e) => {
    e.preventDefault();
    const pageNumber = parseInt(jumpToPage);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      setJumpToPage('');
    }
  };

  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  return (
    <div className="transaction-list-container">
      <div className="transaction-controls">
        <div className="items-per-page">
          <label>Items per page:</label>
          <select 
            value={itemsPerPage} 
            onChange={handleItemsPerPageChange}
          >
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>

        <div className="pagination-controls">
          <button 
            onClick={handlePrevPage} 
            disabled={currentPage === 1}
            className="page-button"
          >
            Previous
          </button>
          
          <span className="page-info">
            Page {currentPage} of {totalPages}
          </span>

          <button 
            onClick={handleNextPage} 
            disabled={currentPage === totalPages}
            className="page-button"
          >
            Next
          </button>

          <form onSubmit={handleJumpToPage} className="jump-to-page">
            <input
              type="number"
              min="1"
              max={totalPages}
              value={jumpToPage}
              onChange={(e) => setJumpToPage(e.target.value)}
              placeholder="Go to page"
            />
            <button type="submit" className="page-button">Go</button>
          </form>
        </div>
      </div>

      <div className="transaction-list">
        {currentTransactions.map((tx) => (
          <div key={tx.transaction_link} className="transaction-item">
            <p className="transaction-text">
              <span className="solana-icon">
                <img alt="Solana" src="/icons/solana.png" />
              </span>
              {getActionEmoji(tx.action)}
              <a 
                href={`https://solscan.io/address/${tx.wallet_address}`} 
                target="_blank" 
                rel="noopener noreferrer"
                className="wallet-name"
              >
                {tx.wallet_name}
              </a>
              <em className="wallet-short">
                ({tx.wallet_address.slice(0, 3)})
              </em>
              <em>{tx.action}</em>
              
              {tx.action !== 'received' && (
                <>
                  {tx.action === 'sold' ? (
                    // For 'sold' transactions
                    <>
                      {tx.deal_token_amount && <span>{tx.deal_token_amount}</span>}
                      <a 
                        href={`https://solscan.io/token/${tx.deal_token_address}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="token-link"
                      >
                        {tx.deal_token_name}
                      </a>
                      <em>{formatTokenInfo(tx.deal_token_info)}</em>
                      <em>for</em>
                      <span>{tx.owner_token_amount}</span>
                      <a 
                        href={`https://solscan.io/token/${tx.owner_token_address}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="token-link"
                      >
                        {tx.owner_token_name}
                      </a>
                      <em>{formatTokenInfo(tx.owner_token_info)}</em>
                    </>
                  ) : (
                    // For 'bought' transactions
                    <>
                      {tx.owner_token_amount && <span>{tx.owner_token_amount}</span>}
                      <a 
                        href={`https://solscan.io/token/${tx.owner_token_address}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="token-link"
                      >
                        {tx.owner_token_name}
                      </a>
                      <em>{formatTokenInfo(tx.owner_token_info)}</em>
                      <em>with</em>
                      {tx.deal_token_amount && <span>{tx.deal_token_amount}</span>}
                      <a 
                        href={`https://solscan.io/token/${tx.deal_token_address}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="token-link"
                      >
                        {tx.deal_token_name}
                      </a>
                      <em>{formatTokenInfo(tx.deal_token_info)}</em>
                    </>
                  )}
                </>
              )}
              {tx.action === 'received' && tx.gift_sender_address && (
                <>
                  {tx.gift_token_amount && <span>{tx.gift_token_amount}</span>}
                  <a 
                    href={`https://solscan.io/token/${tx.gift_token_address}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="token-link"
                  >
                    {tx.gift_token_name}
                  </a>
                  <em>from</em>
                  <a 
                    href={`https://solscan.io/account/${tx.gift_sender_address}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="wallet-link"
                  >
                    {tx.gift_sender_name || `${tx.gift_sender_address.slice(0, 4)}...${tx.gift_sender_address.slice(-4)}`}
                  </a>
                </>
              )}

              <em>{formatTimeAgo(tx.time_frame)}</em>
              <a 
                href={`https://solscan.io/tx/${tx.transaction_link}`}
                target="_blank"
                rel="noopener noreferrer"
                className="transaction-link"
              >
                ⎋
              </a>
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TransactionList; 