import React from 'react';
import './ChartAnalysis.css';

function ChartAnalysis({ transactions }) {
  // Basic statistics calculation
  const stats = transactions.reduce((acc, tx) => {
    if (tx.action === 'bought') acc.buys++;
    if (tx.action === 'sold') acc.sells++;
    if (tx.action === 'received') acc.received++;
    return acc;
  }, { buys: 0, sells: 0, received: 0 });

  return (
    <div className="chart-analysis">
      <h2>Transaction Summary</h2>
      <div className="stats-grid">
        <div className="stat-card">
          <h3>Buy Transactions</h3>
          <p className="stat-number green">{stats.buys}</p>
        </div>
        <div className="stat-card">
          <h3>Sell Transactions</h3>
          <p className="stat-number red">{stats.sells}</p>
        </div>
        <div className="stat-card">
          <h3>Received</h3>
          <p className="stat-number blue">{stats.received}</p>
        </div>
      </div>
    </div>
  );
}

export default ChartAnalysis; 